import React, { useState, useEffect } from "react";
import Tabs from "../../employeeTabs";
import Layout from "../../../containers/Layout";
import config from "../../../config";
import { Modal, notification, Drawer, Pagination, Button, Spin } from "antd";
import { useHttp } from "../../../hooks/useHttps";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
export default function EmployeeInfo(props) {
  const [visible, setVisible] = useState(false);
  const [rowId, setRowID] = useState("");
  const showDraw = (id) => {
    setVisible(true);
    setRowID(id.id);
  };

  const [noPensionDetails, setNoPensionDetails] = useState(false);
  const [loadPensionDetails, setLoadPensionDetails] = useState(true);
  const [pensionDetailsData, setPensionDetails] = useState([]);

  const [listCheck, setListCheck] = useState([]);

  const handleCheckboxChange = (id) => {
    const updatedData = pensionDetailsData.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setPensionDetails(updatedData);
    setListCheck(updatedData?.filter((item) => item?.checked));
    const allChecked = updatedData.every((item) => item.checked);
  };

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const pensionId = props.history.location.state?.pensionId
    ? props.history.location.state?.pensionId
    : "";

  useEffect(() => {
    getPensionDetail();
  }, []);

  const getPensionDetail = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pension-details/employer/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}&pensionId=${pensionId}&page=0&size=50`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setTotalItems(res.data.totalPages * 10);
            setNoPensionDetails(false);
            setLoadPensionDetails(false);
            setPensionDetails(res.data.result);
          } else {
            setNoPensionDetails(true);
            setLoadPensionDetails(false);
            setPensionDetails([]);
          }
        } else {
          setNoPensionDetails(true);
          setLoadPensionDetails(false);
          setPensionDetails([]);
        }
      })
      .catch((err) => {
        setNoPensionDetails(true);
        setLoadPensionDetails(false);
        if (err) {
        }
      });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const closeDraw = () => {
    setVisible(false);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const retryPension = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to re-try this pension detail?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/pension-details/retry/${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setVisible(false);
              Notification("success", "Success", res.data.message);
              getPensionDetail();
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              Notification("error", "Error", "Failed to connect to SalarioPay");
            }
          });
      },

      onCancel() {},
    });
  };

  const deletePension = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to delete this pension detail?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .delete(`${config.baseUrl}/v1/pension-details/${rowId}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status) {
              setVisible(false);
              Notification("success", "Success", res.data.message);
              getPensionDetail();
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              Notification("error", "Error", "Failed to connect to SalarioPay");
            }
          });
      },
      onCancel() {},
    });
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `employerId=${sessionStorage.getItem(
      "employer_id"
    )}&pensionId=${pensionId}&page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(
        `${config.baseUrl}/v1/pension-details/employer/paged?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setTotalItems(res.data.totalPages * 10);
            setPensionDetails(res.data.result);
          } else {
            setNoPensionDetails(true);
            setLoadPensionDetails(false);
            setPensionDetails([]);
          }
        } else {
          setNoPensionDetails(true);
          setLoadPensionDetails(false);
          setPensionDetails([]);
        }
      })
      .catch((err) => {
        setNoPensionDetails(true);
        if (err) {
        }
      });
  };

  const syncPension = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to sync this pension remittance?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/pension-details/sync-pension-remittance-detail/${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setVisible(false);
              Notification("success", "Success", res.data.message);
              getPensionDetail();
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              Notification("error", "Error", "Failed to connect to SalarioPay");
            }
          });
      },

      onCancel() {},
    });
  };

  const retryPensionBulk = (id) => {
    axios
      .put(
        `${config.baseUrl}/v1/pension-details/retry`,
        {
          detailIds: listCheck?.map((item) => {
            return item.id;
          }),
          employerId: sessionStorage?.getItem("employer_id"),
          pensionId: pensionId,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const showDeleteConfirmPensionlRetry = (e) => {
    e.preventDefault();
    if (listCheck?.length < 1) {
      Notification("error", "Error", 'Please select pension details before you can Retry');
      return;
    }
    Modal.confirm({
      title: `Are you sure want to retry the selected pension payout(s)?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        retryPensionBulk();
      },
      onCancel() {},
    });
  };

  return (
    <Layout>
      <div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card table-widget">
              <div class="card-body">
              <div className="d-flex justify-content-between align-items-top">
                  <div><h5 class="card-title">Pension Details </h5></div>
                  <div className="">
                    <Button
                      className=""
                      onClick={showDeleteConfirmPensionlRetry}
                      style={{
                        background: listCheck?.length > 0 ? "#1FC157" : "grey",
                        color: listCheck?.length > 0 ? "white" : "silver",
                        marginRight:'2rem'
                      }}

                    >
                      Retry
                    </Button>

                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th scope="col">Employee Code</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Employee Amount</th>
                        <th scope="col">Employer Amount</th>
                        <th scope="col">Fee</th>
                        <th scope="col">PFA Name</th>
                        <th scope="col">RSA Pin</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pensionDetailsData.map((data) => {
                        return (
                          <tr>
                            <td>
                              <div class="form-check">
                                <input
                                  class="form-check-input border-checks"
                                  type="checkbox"
                                  checked={data.checked}
                                  onChange={() => handleCheckboxChange(data.id)}
                                />
                              </div>
                            </td>
                            <td>{data.employeeCode}</td>
                            <td>{data.firstName}</td>
                            <td>{data.lastName}</td>

                            <td>
                              {" "}
                              {data.currency}{" "}
                              {data.pensionContribution
                                ? Intl.NumberFormat("en-US").format(
                                    data.pensionContribution
                                  )
                                : 0}
                            </td>
                            <td>
                              {" "}
                              {data.currency}{" "}
                              {data.employeeContribution
                                ? Intl.NumberFormat("en-US").format(
                                    data.employeeContribution
                                  )
                                : 0}
                            </td>
                            <td>
                              {" "}
                              {data.currency}{" "}
                              {data.employerContribution
                                ? Intl.NumberFormat("en-US").format(
                                    data.employerContribution
                                  )
                                : 0}
                            </td>

                            <td>
                              {data.currency} {data.fee}
                            </td>

                            <td>{data.pfaName} </td>
                            <td>{data.rsaPin} </td>

                            <td>
                              {data.createdAt
                                ? data.createdAt.slice(0, 10)
                                : ""}
                            </td>
                            <td>
                              <span
                                className={
                                  {
                                    SUCCESS: "badge bg-success",
                                    FAILED: "badge bg-danger",
                                    NEW: "badge bg-info",
                                    PENDING: "badge bg-warning",
                                    PROCESS: "badge bg-warning",
                                    TIMEOUT: "badge bg-danger",
                                  }[data.status]
                                }
                              >
                                {data.status}
                              </span>
                            </td>
                            <td>
                              <Button
                                onClick={showDraw.bind(this, data)}
                                type="primary"
                                danger
                                style={{
                                  background: "#1FC157",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                              >
                                Actions
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {loadPensionDetails && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
                {noPensionDetails && (
                  <p className="text-center pt-5 pb-5">No Pension Details.</p>
                )}
                {pensionDetailsData?.length > 0 && (
                  <div className="text-center mt-5 mb-5">
                    <Pagination
                      current={page}
                      total={totalItems}
                      defaultPageSize={10}
                      onChange={pagination}
                      itemRender={itemRender}
                    />{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Drawer
          title="Actions"
          placement="right"
          visible={visible}
          onClose={closeDraw}
        >
          <div
            style={{ color: "#000000", cursor: "pointer" }}
            onClick={retryPension}
          >
            Retry Pension
          </div>
          <hr />
          <div
            style={{ color: "#000000", cursor: "pointer" }}
            onClick={syncPension}
          >
            Sync Detail
          </div>
          <hr />
          <div
            style={{ color: "red", cursor: "pointer" }}
            onClick={deletePension}
          >
            {" "}
            Delete
          </div>
          <hr />
        </Drawer>
      </div>
    </Layout>
  );
}
