import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import Tab from "../../tab";
import ReactDatatable from "@ashvin27/react-datatable";
import { useHttp } from "../../../hooks/useHttps";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Pagination,
  Modal,
  notification,
} from "antd";
import axios from "axios";
import config from "../../../config";
export default function BenefitDetail(props) {
  const [benefitDetailData, setBenefitDetailData] = useState([]);
  const [load, setLoad] = useState(false);
  const [nobenefitDetailDatas, setNoBenefitDetail] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [benefitId, setBenefitId] = useState("");
  const [listCheck, setListCheck] = useState([]);
  const [loadbenefitDetailDatas, setLoadBenefitDetail] = useState(true);
  const [benefitStatus, setBenefitStatus] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [id, setID] = useState("");

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");

  const showDrawer = (data, e) => {
    e.preventDefault();
    setID(data.id);
    setVisibleDraw(true);
    setBenefitStatus(data.status);
  };

  const getBenefitDetail = (benefitId) => {
    setBenefitId(benefitId);
    axios
      .get(
        `${config.baseUrl}/v1/benefit-details/paged?benefitId=${benefitId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const transactionsData = res.data.result;
          if (transactionsData.length > 0) {
            setBenefitDetailData(transactionsData);
            setLoadBenefitDetail(false);
            setNoBenefitDetail(false);
          } else {
            setLoadBenefitDetail(false);
            setNoBenefitDetail(true);
          }
        } else {
          setLoadBenefitDetail(false);
          setNoBenefitDetail(true);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    const benefitId = props.location.state.benefitId
      ? props.location.state.benefitId
      : "";
    getBenefitDetail(benefitId);
  }, []);

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to delete this Benefit Detail?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteBenefit();
      },
      onCancel() { },
    });
  };

  const retryBenefitConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to retry this Benefit Detail?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        retryBenefit();
      },
      onCancel() { },
    });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Benefit Detail?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() { },
    });
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  const changeStatusFunc = () => {
    axios
      .put(
        `${config.baseUrl}/v1/benefits/status/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getBenefitDetail();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          getBenefitDetail();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const deleteBenefit = () => {
    axios
      .delete(`${config.baseUrl}/v1/benefit-details/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getBenefitDetail();
          setVisibleDraw(false);
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handleCheckboxChange = (id) => {
    const updatedData = benefitDetailData.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setBenefitDetailData(updatedData);
    setListCheck(updatedData?.filter((item) => item?.checked));
    const allChecked = updatedData.every((item) => item.checked);
    setSelectAll(allChecked);
  };

  const retryBenefit = () => {
    axios
      .put(
        `${config.baseUrl}/v1/benefit-details/retry/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getBenefitDetail();
          setVisibleDraw(false);
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const retryBenefitBulk = (id) => {
    axios
      .put(
        `${config.baseUrl}/v1/benefit-details/retry`,
        {
          detailIds: listCheck?.map((item) => {
            return item.id;
          }),
          employerId: sessionStorage?.getItem("employer_id"),
          benefitId: benefitId,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getBenefitDetail();
        } else {
          Notification("error", "Error", res.data.message);

        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const showDeleteConfirmBulkRetry = (e) => {
    e.preventDefault();
    if (listCheck?.length < 1) {
      Notification("error", "Error", 'Please select benefit details before you can Retry');
      return;
    }
    Modal.confirm({
      title: `Are you sure want to retry the selected benefit payout(s)?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        retryBenefitBulk();
      },
      onCancel() { },
    });
  };

  return (
    <Layout>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-top">
                <div><h5 class="card-title">Benefit Details </h5></div>
                <div className="">
                  <Button
                    className=""
                    onClick={showDeleteConfirmBulkRetry}
                    style={{
                      background: listCheck?.length > 0 ? "#1FC157" : "grey",
                      color: listCheck?.length > 0 ? "white" : "silver",
                      marginRight: '2rem'
                    }}

                  >
                    Retry
                  </Button>

                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th scope="col">Employee Name</th>
                      <th scope="col">Employee Number</th>
                      <th scope="col"> Benefit Type</th>
                      <th scope="col">Biller Name</th>
                      <th scope="col"> Bill Plan </th>

                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>

                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {benefitDetailData.length > 0 && (
                      <>
                        {benefitDetailData.map((data) => {
                          return (
                            <tr>
                              <td>
                                <div class="form-check">
                                  <input
                                    class="form-check-input border-checks"
                                    type="checkbox"
                                    checked={data.checked}
                                    onChange={() =>
                                      handleCheckboxChange(data.id)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                {data?.employeeName ? data.employeeName : "NA"}
                              </td>
                              <td>{data.employeeNumber}</td>
                              <td>
                                {data.type ? data.type.replace(/_+/g, " ") : ""}
                              </td>

                              <td>
                                {data.billerName ? data.billerName : "NA"}
                              </td>
                              <td>
                                {data.billerPlanName
                                  ? data.billerPlanName
                                  : "NA"}
                              </td>

                              <td>
                                {data.currency}{" "}
                                {data?.amount
                                  ? Intl.NumberFormat("en-US").format(
                                    data.amount
                                  )
                                  : 0}
                              </td>
                              <td>
                                <span
                                  className={
                                    {
                                      SUCCESS: "badge bg-success",
                                      NEW: "badge bg-info",
                                      FAILED: "badge bg-danger",
                                      PENDING: "badge bg-warning",
                                    }[data.status]
                                  }
                                >
                                  {data.status}
                                </span>
                              </td>
                              <td>
                                <button
                                  type="submit"
                                  onClick={showDrawer.bind(this, data)}
                                  class="btn btn-primary"
                                  style={{
                                    background: "#1FC157",
                                    color: "white",
                                  }}
                                >
                                  Action
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
                {nobenefitDetailDatas && (
                  <p className="text-center pt-5 pb-5">No Benefit Detail</p>
                )}
                {loadbenefitDetailDatas && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
              </div>
              <div className="d-flex flex justify-content-center align-items-center py-5">
                <Pagination defaultCurrent={1} total={1} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          style={{ color: "", cursor: "pointer" }}
          onClick={retryBenefitConfirm}
        >
          Retry
        </div>
        <hr />
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}
        >
          Delete
        </div>
        <hr />
      </Drawer>
    </Layout>
  );
}
